/* eslint-disable import/extensions */
import { lazy } from 'react';
import {
  ENROLMENT_PAYMENT,
  ENROLMENT,
  ENRICHMENT_PROGRAM_LIST,
  ENRICHMENT_PARENT_CLASS_DETAIL,
  PAYMENT_DETAILS,
  MY_DOWNLOADS,
  ENRICHMENT_MY_CLASS_LIST,
  ENRICHMENT_MY_CLASS_DETAIL,
} from '../constants';
import CommonProtectedRoutes from './common';

const ParentRegistration = lazy(() =>
  import('../../components/views/RegistrationV2')
);
const ProgramListingMainComponent = lazy(() =>
  import(
    '../../components/views/EnrichmentProgramme/ParentPortal/ProgramList/index'
  )
);
const EnrichmentParentClassDetails = lazy(() =>
  import(
    '../../components/views/EnrichmentProgramme/ParentPortal/ClassInfo/index'
  )
);
const PaymentDetails = lazy(() =>
  import('../../components/views/PaymentDetails')
);

const MyDownloads = lazy(() =>
  import('../../components/views/MyDownloads/index.js')
);

const MyClassesMainComponent = lazy(() =>
  import('../../components/views/EnrichmentProgramme/ParentPortal/MyClasses')
);

const MyClassesDetail = lazy(() =>
  import(
    '../../components/views/EnrichmentProgramme/ParentPortal/MyClasses/ClassDetail'
  )
);

const parentRoutes = [
  ...CommonProtectedRoutes,
  {
    url: ENROLMENT_PAYMENT,
    component: {
      default: ParentRegistration,
    },
  },
  {
    url: ENROLMENT,
    component: {
      default: ParentRegistration,
    },
  },
  {
    url: ENRICHMENT_PROGRAM_LIST,
    component: {
      default: ProgramListingMainComponent,
    },
  },
  {
    url: ENRICHMENT_PARENT_CLASS_DETAIL,
    component: {
      default: EnrichmentParentClassDetails,
    },
  },
  {
    url: PAYMENT_DETAILS,
    component: {
      default: PaymentDetails,
    },
  },
  {
    url: MY_DOWNLOADS,
    component: {
      default: MyDownloads,
    },
  },
  {
    url: ENRICHMENT_MY_CLASS_LIST,
    component: {
      default: MyClassesMainComponent,
    },
  },
  {
    url: ENRICHMENT_MY_CLASS_DETAIL,
    component: {
      default: MyClassesDetail,
    },
  },
];

export default parentRoutes;
